.outergrid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "blank1 favourable  blank2"
    "blank1 price  blank2";

  & > :nth-child(1) {
    height: 2rem;
    margin-bottom: 0.5rem;
    grid-area: favourable;
    align-self: center;
    justify-self: center;
  }

  & > :nth-child(2) {
    grid-area: price;
    align-self: center;
    justify-self: center;
  }
}

.innergrid {
  display: grid;
  border: 1px solid var(--selected-shadow-color);
  border-radius: 10px;
  background-color: var(--selected-pure-color);
  grid-row-gap: 1.5rem;
  width: 100%;
  padding: 0.5rem;
  margin: 0 auto;
  grid-template-columns: auto repeat(2, minmax(min-content, max-content)) auto;
  grid-template-areas:
    "blank1 line line blank2"
    "blank1 mainTitle mainTitle blank2"
    "blank1 price perMonth blank2"
    "blank1 button button blank2"
    "blank1 description description blank2"
    "blank1 features features blank2";

  & > :nth-child(1) {
    grid-area: line;
    width: 100%;
    height: 0.5rem;
    border-radius: 10px;
    align-self: center;
    justify-self: center;
  }

  & > :nth-child(2) {
    height: 1rem;
    grid-area: mainTitle;
    align-self: center;
    justify-self: center;
  }

  & > :nth-child(3) {
    height: 2.5rem;
    grid-area: price;
    align-self: center;
    justify-self: end;
  }

  & > :nth-child(4) {
    grid-area: perMonth;
    align-self: end;
    margin-left: 0.3rem;
    justify-self: start;
  }

  & > :nth-child(5) {
    grid-area: button;
    margin: 1rem 0;
    align-self: center;
    justify-self: center;
    max-width: 100%;
    height: 3rem;
  }

  & > :nth-child(6) {
    grid-area: description;
    height: 2rem;
    align-self: center;
    justify-self: center;
  }

  & > :nth-child(7) {
    border-top: 1px solid var(--selected-shadow-color);
    padding-top: 1rem;
    grid-area: features;
    align-self: center;
    justify-self: start;
  }
}

.features {
  padding: 0;
  margin: 0;

  & > li {
    list-style-type: "✓ " !important;
    color: var(--selected-font-color) !important;
    font-size: calc(var(--font-size-small) - 0.2rem);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-left: 15px !important;
    list-style-position: outside;
    text-align: left;
  }
}

.favorable {
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  color: var(--selected-background-color);
}

.customized-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  font-size: var(--font-size-small);
}

.note {
  & > p {
    font-size: calc(var(--font-size-small), - 1.5rem);
  }
}

.form-inputs {
  display: grid;
  width: 100%;
  grid-template-areas:
    "firstName lastName"
    "workEmail JobTitle"
    "phoneNumber phoneNumber"
    "companyName companySize"
    "request request"
    "button button";

  & > :nth-child(1) {
    grid-area: firstName;
  }

  & > :nth-child(2) {
    grid-area: lastName;
  }

  & > :nth-child(3) {
    grid-area: workEmail;
  }

  & > :nth-child(4) {
    grid-area: JobTitle;
  }

  & > :nth-child(5) {
    grid-area: phoneNumber;
  }

  & > :nth-child(6) {
    grid-area: companyName;
  }

  & > :nth-child(7) {
    grid-area: companySize;
  }

  & > :nth-child(8) {
    grid-area: request;
  }

  & > :nth-child(9) {
    grid-area: button;
  }
}
