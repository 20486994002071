.header-grid {
  grid-area: page;
  display: grid;
  position: fixed;
  align-self: center;
  justify-self: center;
  width: 100vw;
  top: 0;
  left: 0;
  padding-bottom: 0.7rem;
  grid-template-rows: repeat(2, minmax(min-content, max-content));
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "blank1 links blank2"
    "blank1 mainTitle blank2";
  background-color: var(--selected-action-color-4);
  box-shadow: 0 4px 8px 0 var(--selected-shadow-color), 0 6px 20px 0 var(--selected-shadow-color);
  font-family: var(--selected-design-font);
  z-index: 7;

  & > :nth-child(1) {
    padding-top: 1rem;
    grid-area: links;
    align-self: center;
    justify-self: center;
    display: grid;
    min-width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(2rem, 1fr));
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & > :nth-child(2) {
    padding-top: 0.5rem;
    font-weight: 100;
    grid-area: mainTitle;
    align-self: center;
    justify-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90vw;
    color: var(--dark-font-color);
  }
}

.ios {
  padding-top: 1.2rem;
}

.transition-effect {
  transition: transform 0.3s ease;
}

.shrink {
  transition: transform 0.3s ease;
  transform: translate(0, -45%);
}

.reformat-headline {
  transition: transform 0.3s ease;
  transform: scale(90%);
}

.ios-empty-object {
  height: 11.5rem;

  @media (--viewport-s) {
    height: 13.5rem;
  }
}

.empty-object {
  height: 10.5rem;

  @media (--viewport-s) {
    height: 12.5rem;
  }
}

.next-sibling-less-margin + * {
  margin-top: -4rem !important;
}

.link-wrapper {
  box-sizing: border-box;
  text-decoration: none;
  white-space: nowrap;
  display: grid;
  align-self: center;
  justify-self: center;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  grid-template-areas: "linkIcon linkText";
  grid-column-gap: 0.2rem;
  padding: 1.5rem 1rem;
  cursor: pointer;
  font-size: var(--font-size-small);
  color: var(--dark-font-color);
  background-color: transparent;
  border: 0;
  font-weight: 400;

  &:hover,
  &:focus,
  &:active {
    color: var(--selected-action-color-3) !important;
    border: none;
  }

  @media (--viewport-l) {
    padding-bottom: 1rem;
  }
}
