.innergrid {
  display: grid;
  border: 1px solid var(--selected-shadow-color);
  border-radius: 10px;
  background-color: var(--selected-pure-color);
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  grid-template-areas:
    "mainTitle mainTitle"
    "price perMonth"
    "description description"
    "features features";

  & > :nth-child(1) {
    width: 100%;
    grid-area: mainTitle;
    align-self: center;
    justify-self: center;
    text-align: center;
  }

  & > :nth-child(2) {
    padding-top: 1rem;
    grid-area: price;
    align-self: center;
    justify-self: end;
  }

  & > :nth-child(3) {
    padding-top: 2.5rem;
    grid-area: perMonth;
    align-self: end;
    margin-left: 0.3rem;
    justify-self: start;
  }

  & > :nth-child(4) {
    padding-top: 1rem;
    grid-area: description;
    align-self: center;
    justify-self: center;
  }
}
